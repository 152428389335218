// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.sidebar {
    padding-bottom: 0px !important;
}

.modal-header {
    position: relative;
}
.modal-header h2 {
    display: inline;
    float: left;
    margin-top: 0px;
}
.modal-header span {
    display: inline;
    float: right;
    font-size: 40px;
    margin-top: -8px;
    cursor: pointer;
}
.modal_back_link {
    color: #0079b5;
    cursor: pointer;
}
.presets_container {
    position: relative;
    margin-top: 40px;
}
.presets_container .preset_container {
    display: inline;
    float: left;
    padding: 0px 10px 20px 10px;
    cursor: pointer;
}
.preset_container img {
    max-width: 250px;
}
.preset_container .title {
    text-align: center;
    color: #0079b5;
    font-weight: bold;
}

`, "",{"version":3,"sources":["webpack://./../src/index.css"],"names":[],"mappings":";AACA;IACI,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;IACX,eAAe;AACnB;AACA;IACI,eAAe;IACf,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,WAAW;IACX,2BAA2B;IAC3B,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,cAAc;IACd,iBAAiB;AACrB","sourcesContent":["\r\n.sidebar {\r\n    padding-bottom: 0px !important;\r\n}\r\n\r\n.modal-header {\r\n    position: relative;\r\n}\r\n.modal-header h2 {\r\n    display: inline;\r\n    float: left;\r\n    margin-top: 0px;\r\n}\r\n.modal-header span {\r\n    display: inline;\r\n    float: right;\r\n    font-size: 40px;\r\n    margin-top: -8px;\r\n    cursor: pointer;\r\n}\r\n.modal_back_link {\r\n    color: #0079b5;\r\n    cursor: pointer;\r\n}\r\n.presets_container {\r\n    position: relative;\r\n    margin-top: 40px;\r\n}\r\n.presets_container .preset_container {\r\n    display: inline;\r\n    float: left;\r\n    padding: 0px 10px 20px 10px;\r\n    cursor: pointer;\r\n}\r\n.preset_container img {\r\n    max-width: 250px;\r\n}\r\n.preset_container .title {\r\n    text-align: center;\r\n    color: #0079b5;\r\n    font-weight: bold;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
